<script setup>
import { ref, defineEmits, defineExpose, onMounted } from 'vue'

const cSize = 160
const drawFPS = 30
const palatte = [
  [15, 56, 15],
  [48, 98, 48],
  [139, 172, 15],
  [155, 188, 15],
  [202, 220, 159]
]
let lastTimestamp = 0
let cameraEnabled = false

const video = ref(null)
const canvas = ref(null)

const emit = defineEmits({
  cameraEnabled: {
    type: Boolean,
    default: false
  }
})

function enableCamera() {
  window.navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(stream => {
      emit('cameraEnabled', true)
      video.value.srcObject = stream
      video.value.play()
      draw()
    })
    .catch(() => {
      emit('cameraEnabled', false)
      window.alert('Unable to access the camera! Make sure to allow access.')
    })
}

function draw(timestamp) {
  if (!canvas.value) return

  requestAnimationFrame(draw)
  if (timestamp - lastTimestamp < 1000 / drawFPS) return

  const ctx = canvas.value.getContext('2d')
  const vWidth = video.value.videoWidth
  const vHeight = video.value.videoHeight

  const vSize = vWidth > vHeight ? vHeight : vWidth
  const vX = vWidth > vHeight ? (vWidth - vSize) / 2 : 0
  const vY = vWidth > vHeight ? 0 : (vHeight - vSize) / 2

  ctx.drawImage(
    video.value,
    vX, vY,
    vSize, vSize,
    0, 0,
    cSize, cSize
  )

  filter(ctx)

  lastTimestamp = timestamp
}

function filter(ctx) {
  const imageData = ctx.getImageData(0, 0, cSize, cSize)
  for (let i = 0; i < imageData.data.length; i += 4) {
    // Covert RGB to brightness
    const l = 0.299 * imageData.data[i]
      + 0.587 * imageData.data[i + 1]
      + 0.114 * imageData.data[i + 2]

    // Pick color from palette based on brightness
    const color = palatte[Math.floor(l / 256 * palatte.length)]

    imageData.data[i] = color[0]
    imageData.data[i + 1] = color[1]
    imageData.data[i + 2] = color[2]
  }

  ctx.putImageData(imageData, 0, 0)
}

async function downloadImage() {
  const largeCanvas = document.createElement('canvas')
  largeCanvas.width = largeCanvas.height = cSize * 5

  const largeCtx = largeCanvas.getContext('2d')
  largeCtx.imageSmoothingEnabled = false
  largeCtx.drawImage(canvas.value, 0, 0, cSize, cSize, 0, 0, cSize * 5, cSize * 5)

  const blob = await new Promise(resolve => largeCanvas.toBlob(resolve))
  const link = document.createElement('a')
  link.download = 'gb_photo.png'
  link.href = window.URL.createObjectURL(blob)
  link.click()
}

onMounted(() => {
  canvas.value.width = canvas.value.height = cSize
})

defineExpose({
  enableCamera,
  downloadImage,
  cameraEnabled,
})

</script>

<template>
  <video ref="video" playsinline autoplay></video>
  <canvas ref="canvas"></canvas>
</template>

<style scoped>
video { display: none; }
canvas {
  width: 100%;
  image-rendering: pixelated;
  background-color: #cadc9f;
}
</style>
