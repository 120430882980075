<template>
  <div class="shell">
    <div class="bezel">
      <div :class="{
        'led': true,
        'on': cameraEnabled
      }" />
      <Camera ref="camera" @cameraEnabled="toggleCameraState"  />
    </div>

    <button
      class="gbButton" v-if="!cameraEnabled"
      @click="$refs.camera.enableCamera()"
    >
      Enable Camera
    </button>

    <button
      class="gbButton" v-if="cameraEnabled"
      @click="$refs.camera.downloadImage()"
    >
      Download Photo
    </button>
  </div>

  <p style="max-width: 28rem; padding: 1rem;">
    This web app requires access to your camera in order to function.
    The filter effect occurs <em>only</em> within your browser.
    <strong>No photographic information or pictures leave your computer.</strong>
    Please make sure to click "allow" when prompted.
  </p>
</template>

<script>
import Camera from './components/Camera.vue'

export default {
  name: 'App',
  components: {
    Camera
  },
  data: () => ({
    cameraEnabled: false
  }),
  methods: {
    toggleCameraState(state) {
      this.cameraEnabled = state
    }
  }
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 96vh;
}

.shell {
  width: calc(100% - 4rem);
  max-width: 30rem;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
  background-color: #c4bebb;
  box-shadow: inset 0 0 0.25rem #666;
}

.bezel {
  position: relative;
  width: calc(100% - 4rem);
  padding: 1rem 1rem 1rem 3rem;
  border-radius: 0.25rem;
  background-color: #272929;
  box-shadow: inset 0 0 0.25rem #aaa;
}
.bezel > .led {
  position: absolute;
  top: 33%; left: calc(1.5rem - 6px);
  width: 12px; height: 12px;
  border-radius: 50%;
  background-color: #300;
  box-shadow: inset 0 0 8px #000;
}
.bezel > .led.on {
  background-color: #f00;
}

.gbButton {
  display: block;
  width: 100%;
  margin: 1rem 0 0;
  padding: 1rem 2rem;
  border: 1px solid #272929;
  border-radius: 0.25rem;
  background-color: #9a2257;
  box-shadow: inset 0 0 0.25rem #c27a9a, 0 0 0.125rem #000;
  color: #dadada;
  font-size: 1.25rem;
  font-weight: bold;
}
.gbButton:hover {
  opacity: 0.75;
}
.gbButton:active {
  opacity: 1;
  background-color: #5c1434;
  box-shadow: inset 0 0 0.5rem #9a2257, 0 0 0.125rem #000;
}
</style>
